// // 设置 rem 函数
// function setRem() {
//   // 375px iphone6 屏幕的大小; 375px = 20rem; 1rem = 18.75px
//   let htmlWidth =
//     document.documentElement.clientWidth || document.body.clientWidth;
//   //得到html的Dom元素
//   let htmlDom = document.getElementsByTagName("html")[0];
//   //设置根元素字体大小
//   htmlDom.style.fontSize = (htmlWidth * 2) / 20 + "px";
// }
// // 初始化
// setRem();
// // 改变窗口大小时重新设置 rem
// window.onresize = function () {
//   setRem();
// };

(function (doc, win) {
  let docEl = doc.documentElement;
  let isIOS = navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  let dpr = isIOS ? Math.min(win.devicePixelRatio, 3) : 1;
  dpr = window.top === window.self ? dpr : 1;
  // 被iframe引用时，禁止缩放
  dpr = 1;
  let scale = 1 / dpr;
  let resizeEvt =
    "orientationchange" in window ? "orientationchange" : "resize";
  docEl.dataset.dpr = dpr;
  let metaEl = doc.createElement("meta");
  metaEl.name = "viewport";
  metaEl.content =
    "initial-scale=" +
    scale +
    ",maximum-scale=" +
    scale +
    ", minimum-scale=" +
    scale;
  docEl.firstElementChild.appendChild(metaEl);
  let recalc = function () {
    let width = docEl.clientWidth;
    if (width / dpr > 750) {
      width = 750 * dpr;
    }
    // 乘以100，px : rem = 100 : 1
    docEl.style.fontSize = 100 * (width / 750) + "px";
  };
  recalc();
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
})(document, window);
